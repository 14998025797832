import "leaflet";

export function initCustomZwickau() {
    $(document).ready(function(e){
        if( $('#map').length > 0) {
            showMap( $('#map').is('.detail'));
        }
    });
}

function showMap(detail){
    detail = detail || false;

    L.Icon.Default.imagePath = '/img/';
    var map = L.map('map',{
        center: window.position,
        zoom: detail ? 15 : 11

    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Karte: © Stadt Celle und <a href="http://www.openstreetmap.org/copyright/" rel="external" target="_blank">OpenStreetMap-Mitwirkende</a>.',
        maxZoom: 19,
        id: 'osm',
		/*layers: 'osm,strassen,Gebaeude'*/
		layers: 'osm,strassen',
		crs: L.CRS.EPSG3857
    }).addTo(map);

    if( detail ){

        var marker = new L.marker(window.position).bindPopup(
            '<h4 class="mb-2">'+$('h1').text()+'</h4>'+
            '<p class="adress">'+$('.address').html()+'</p>'
        ).openPopup().addTo(map);
    }else{

        for( var i = 0; i < window.markerPos.length; i++ ) {

            var id = window.markerPos[i].id;
            var marker = new L.marker(window.markerPos[i].position);
            var item = $('ul.list-group li[data-id='+id+']');

            if( item.length ) {
                var address = item.find('.address');
                var links = item.find('.links');

                marker.bindPopup(
                    '<h4 class="mb-2"><a href="/details.php?einrichtung='+id+'">'+item.find('h3').text()+'</a></h4>'+
                    (address.length > 0 ? '<p class="address">'+address.html()+'</p>' : '')+
                    (links.length > 0 ? '<p class="links">'+links.html()+'</p>' : '')
                ).openPopup().addTo(map);
            }
        }
    }
}